<template>
    <transition name="fade">
        <div id="lightingSlider" class="absolute w-72 h-24 flex flex-col justify-center px-6 bg-red-600 border-2 border-gray-900 rounded-3xl text-lg tracking-wide text-white" style="z-index: 9999;">
            Adjust the Lighting
            <vue-slider
                class="mt-2"
                :value="lightLevel"
                @change="lightChanged"
                :min="40"
                :max="90"
                :label="false"
                :dot-size="17"
                :dot-style="{ borderColor: '#000', borderWidth: '2px' }"
                :rail-style="{ backgroundColor: '#222' }"
                :process-style="{ backgroundColor: '#222' }"
                tooltip="none"
            />
        </div>
    </transition>
</template>

<script>
import VueSlider from 'vue-slider-component'

export default {
    props: {
        lightLevel: {
            type: Number,
            required: true
        }
    },
    
    components: {
        VueSlider
    },

    methods: {
        lightChanged(val) {
            this.$emit('light-changed', val)
        }
    },

    mounted() {
        document.addEventListener('click', (e) => {
            // Check if click event is on the lighting div or slider, if not emit a close event
            if(
                !(e.target.id === 'lightingSlider' ||
                e.target.id === 'lightSwitchOverlay' ||
                e.target.classList.contains('vue-slider') ||
                e.target.classList.contains('vue-slider-process') ||
                e.target.classList.contains('vue-slider-dot-handle') ||
                e.target.classList.contains('vue-slider-dot') ||
                e.target.classList.contains('vue-slider-rail'))
            ) {
                this.$emit('close')
            }
        })
    }
}
</script>

<style>
    #lightingSlider {
        right: 2%;
        bottom: 62%;
        box-shadow: 1px 2px 6px #000;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        #lightingSlider {
            left: auto;
            right: 1%;
        }
    }
</style>