<template>
    <div 
        class="sidebar-trigger absolute flex flex-col md:hidden text-white top-0 left-0 bg-red-600 ease-linear duration-300 overflow-hidden"
        :class="{ 'w-screen h-screen': isOpen, 'w-16  h-16': !isOpen }"
    >
        <div
            class="flex flex-row w-full justify-center items-center"
        >
            <svg 
                @click="toggleExpanded" 
                class="flex w-full"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 53"
                style="max-width: 60px;"
            >
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_4" data-name="Layer 4"><line class="cls-2" x1="34.9" y1="20.63" x2="15.87" y2="20.63"/><line class="cls-2" x1="34.9" y1="25.46" x2="15.87" y2="25.46"/><line class="cls-2" x1="34.9" y1="30.28" x2="15.87" y2="30.28"/></g></g>
            </svg>
            
            <transition name="fade">
                <div 
                    class="flex flex-1 text-md font-bold tracking-wide"
                    v-show="isOpen"
                >Explore SkyBar</div>
            </transition>
        </div>
        <transition name="fade">
            <div
                class="bg-black flex flex-col flex-1 py-4"
                v-show="isOpen"
            >
                <div 
                    class="px-8 py-3 text-gray-400 text-xl font-light tracking-wide"
                    @click="itemTapped('drink-menu')"
                >Drink menu</div>
                <div 
                    class="px-8 py-3 text-gray-400 text-xl font-light tracking-wide"
                    @click="itemTapped('trivia')"
                >Trivia game</div>
                <div 
                    class="px-8 py-3 text-gray-400 text-xl font-light tracking-wide"
                    @click="itemTapped('discussion-cards')"
                >Discussion cards</div>
                <div 
                    class="px-8 py-3 text-gray-400 text-xl font-light tracking-wide"
                    @click="itemTapped('art-gallery')"
                >Artwork</div>
                <div 
                    class="px-8 py-3 text-gray-400 text-xl font-light tracking-wide"
                    @click="itemTapped('about')"
                >About</div>
                <div 
                    class="px-8 py-3 text-gray-400 text-xl font-light tracking-wide"
                    @click="itemTapped('gallery')"
                >Gallery</div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isOpen: false
        }
    },

    methods: {
        toggleExpanded() {
            this.isOpen = !this.isOpen
        },

        itemTapped(item) {
            this.isOpen = false
            this.$emit('item-tapped', item)
        }
    }
}
</script>

<style scoped>
    .sidebar-trigger {
        z-index: 9999;
    }

    .cls-1{fill:#e00;}.cls-2{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.46px;}
</style>