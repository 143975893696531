<template>
    <modal @modal-closed="resetAndClose">
        <!-- Flavor Preferences -->
        <h1 class="text-center font-bold text-3xl mb-6" v-show="!showingDrink">It's Happy Hour</h1>
        <div class="text-center mb-3" v-show="!showingDrink">
            Relax, and enjoy a drink!
        </div>
        <div class="text-center mb-3" v-show="!showingDrink">
            Enjoy conversations with others or just take a few moments of downtime to decompress.
        </div>
        <div class="text-center mb-8" v-show="!showingDrink">
            Choose your flavor favorites and get some suggestions for possible options.
        </div>
        <div class="grid grid-cols-2 text-center gap-2 mb-8" v-show="!showingDrink">
            <div 
                class="col border-2 border-black py-3 font-bold hover:text-white hover:bg-black hover:border-black ease-linear duration-150 cursor-pointer"
                :class="{ 'text-white bg-red-600 border-red-600 hover:bg-red-600 hover:border-red-600': selectedFlavor === 'fruity' }"
                @click="selectedFlavor = 'fruity'"
            >Fruity</div>
            <div 
                class="col border-2 border-black py-3 font-bold hover:text-white hover:bg-black hover:border-black ease-linear duration-150 cursor-pointer"
                :class="{ 'text-white bg-red-600 border-red-600 hover:bg-red-600 hover:border-red-600': selectedFlavor === 'bitter' }"
                @click="selectedFlavor = 'bitter'"
            >Bitter</div>
            <div 
                class="col border-2 border-black py-3 font-bold hover:text-white hover:bg-black hover:border-black ease-linear duration-150 cursor-pointer"
                :class="{ 'text-white bg-red-600 border-red-600 hover:bg-red-600 hover:border-red-600': selectedFlavor === 'dry' }"
                @click="selectedFlavor = 'dry'"
            >Dry</div>
            <div 
                class="col border-2 border-black py-3 font-bold hover:text-white hover:bg-black hover:border-black ease-linear duration-150 cursor-pointer"
                :class="{ 'text-white bg-red-600 border-red-600 hover:bg-red-600 hover:border-red-600': selectedFlavor === 'sweet' }"
                @click="selectedFlavor = 'sweet'"
            >Sweet</div>
            <div 
                class="col col-span-2 border-2 border-black py-3 font-bold hover:text-white hover:bg-black hover:border-black ease-linear duration-150 cursor-pointer"
                :class="{ 'text-white bg-red-600 border-red-600 hover:bg-red-600 hover:border-red-600': selectedFlavor === 'choice' }"
                @click="selectedFlavor = 'choice'"
            >I'm not picky, bartender's choice!</div>
        </div>
        <button
            class="rounded-full border-2 border-red-600 font-bold text-xl text-red-600 py-3 bg-white hover:bg-red-600 hover:text-white ease-linear duration-150 cursor-pointer"
            v-show="!showingDrink"
            @click="suggestDrink"
            :disabled="!selectedFlavor"
        >
            Mix It Up
        </button>

        <!-- Selected Drink -->
        <h1 class="text-center font-bold text-3xl mb-1" v-show="showingDrink">{{ currentDrink.name }}</h1>
        <div
            v-show="showingDrink"
            class="mb-2 w-full h-60 bg-center bg-no-repeat bg-contain"
            :style="{ backgroundImage: `url(${currentDrink.img})` }"
        ></div>
        <h2 class="font-bold text-xl mb-2" v-show="showingDrink">Ingredients</h2>
        <ol v-show="showingDrink" class="list-inside list-square mb-3">
            <li
                v-for="(step, index) in currentDrink.ingredients"
                :key="index"
                style="padding-top: 5px;"
            >{{ step }}</li>
        </ol>
        <h2 class="font-bold text-xl mb-2" v-show="showingDrink">Preparation</h2>
        <p class="text-md mb-8" v-show="showingDrink">{{ currentDrink.preparation }}</p>
        <button
            class="rounded-full border-2 border-red-600 font-bold text-xl text-red-600 py-3 bg-white hover:bg-red-600 hover:text-white ease-linear duration-150 cursor-pointer"
            v-show="showingDrink"
            @click="reset"
        >
            Go Back
        </button>
    </modal>
</template>

<script>
import { DRINKS } from '@/config'
import Modal from './Modal'
import ModalBehaviors from '../mixins/ModalBehaviors'

export default {
    components: {
        Modal
    },

    mixins: [ ModalBehaviors ],

    data() {
        return {
            showingDrink: false,
            selectedFlavor: null,
            drinks: DRINKS
        }
    },

    computed: {
        currentDrink() {
            return this.selectedFlavor ? this.drinks[this.selectedFlavor] : {}
        }
    },

    methods: {
        suggestDrink() {
            this.showingDrink = true
        },
        reset() {
            Object.assign(this.$data, this.$options.data.apply(this))
        },
        resetAndClose() {
            this.close()
            setTimeout(() => Object.assign(this.$data, this.$options.data.apply(this)), 500)
        }
    }
}
</script>