<template>
    <modal @modal-closed="close">
         <div class="flex flex-col justify-center text-center relative">
            <h1 class="text-center font-bold text-3xl mb-6">Art Gallery</h1>
            <p class="mb-3">Currently on display at Sky Bar are three paintings by local artist Tanner Gauvin.</p>
            <p class="mb-6 italic">"By utilizing various suggestions of screen—ranging from the fractured image to the reflection, and to bars that suggest a failing display—that re coupled with marks that show human hand, these works develop a visual relationship with human presence and digital technology."</p>
            <p class="mb-1"><em>Electric Green</em>, 36"x48"</p>
            <p class="mb-3">Latex paint on Canvas</p>
            <p class="mb-1"><em>Façade</em>, 36"x48"</p>
            <p class="mb-3">Latex paint on Canvas</p>
            <p class="mb-1"><em>Refraction</em>, 36"x48"</p>
            <p class="mb-8">Latex paint on Canvas</p>
            <a
                class="rounded-full border-2 border-red-600 font-bold text-xl text-red-600 py-3 bg-white hover:bg-red-600 hover:text-white ease-linear duration-150 cursor-pointer"
                target="_blank"
                href="https://www.tannergauvin.com/"
            >
                See More
            </a>
        </div>
    </modal>
</template>

<script>
import Modal from './Modal'
import ModalBehaviors from '../mixins/ModalBehaviors'

export default {
    components: {
        Modal
    },
    mixins: [ ModalBehaviors ]
}
</script>