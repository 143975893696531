<template>
    <transition name="fade">
        <div
            id="backdrop" 
            class="absolute z-50 flex justify-center items-center top-0 left-0 w-screen h-screen bg-black bg-opacity-60"
            @click="closeModal"
        >
            <div 
                class="bg-white flex flex-col w-96 min-h-1/4 h-auto z-60 rounded-3xl shadow-xl py-5 px-6 modal-content relative overflow-hidden bg-contain bg-no-repeat bg-bottom"
                :style="{ backgroundImage: `url(${backgroundImage})` }"
            >
                <div class="flex justify-end">
                    <svg
                        @click="closeModal"
                        id="close-button"
                        class="-mt-3 -mr-4 cursor-pointer"
                        width="25" height="25"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.05 91.05"
                    ><circle cx="45.53" cy="45.53" r="42.53" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:6px"/><line x1="27.23" y1="27.23" x2="63.83" y2="63.83" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:6px"/><line x1="63.83" y1="27.23" x2="27.23" y2="63.83" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:6px"/>
                    </svg>
                </div>
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        backgroundImage: {
            type: String,
            required: false
        }
    },
    methods: {
        closeModal(event) {
            if(event.target.id === 'backdrop' || event.target.id === 'close-button' || event.target.nodeName == 'circle' || event.target.nodeName == 'line') {
                this.$emit('modal-closed')
            }
        }
    }
}
</script>

<style>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>