import "tailwindcss/tailwind.css"
import 'vue-slider-component/theme/default.css'
import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

console.info('inspired by http://imissmybar.com/')

new Vue({
  render: h => h(App),
}).$mount('#app')
