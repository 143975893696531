export const getRandom = (arr, n) => {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len)
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available")
    while (n--) {
        var x = Math.floor(Math.random() * len)
        result[n] = arr[x in taken ? taken[x] : x]
        taken[x] = --len in taken ? taken[len] : len
    }
    return result
}

export const shuffleObject = (obj) => {
    // new obj to return
  let newArray = []
    // create keys array
  var keys = Object.keys(obj)
    // randomize keys array
    keys.sort(function(){return Math.random()- 0.5;})
    // save in new array
    keys.forEach(function(k) {
        newArray.push(k)
    })
  return newArray
}

export const sleep = async (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}