<template>
    <modal @modal-closed="close">
        <div class="flex flex-col justify-center text-center relative" :class="promptContainerClasses">
            <h1 class="text-center font-bold text-3xl mb-8">Discussion Cards</h1>
            <p class="text-xl mb-6">Have the other meeting attendees join and use these discussion cards to get a conversation started.</p>
            <div class="border-2 border-black rounded-lg min-h-40 h-auto mb-12 items-center justify-center flex py-6 px-4" :class="promptTextContainerClasses">
                <p class="text-2xl" :class="promptTextClasses">{{ prompt }}</p>
            </div>
            <button 
                id="prompt-button" 
                class="rounded-full outline-none focus:outline-none border-2 border-red-600 font-bold text-xl text-red-600 py-3 bg-white hover:bg-red-600 hover:text-white ease-linear duration-150 cursor-pointer"
                :class="promptButtonClasses" 
                @click="newPrompt"
            >
                <img v-if="promptButtonIcon" :src="promptButtonIcon" style="display:inline;margin-right: 10px;max-height: 25px;" />
                {{ promptButtonText }}
            </button>
        </div>
    </modal>
</template>

<script>
import Modal from './Modal'
import ModalBehaviors from '../mixins/ModalBehaviors'
import { PROMPTS } from '@/config'

export default {
    components: {
        Modal
    },
    mixins: [ ModalBehaviors ],
    props: {
        availablePrompts: {
            type: Array,
            required: false,
            default: () => PROMPTS
        },
        promptButtonText: {
            type: String,
            required: false,
            default: 'New Card'
        },
        promptButtonIcon: {
            type: String,
            required: false
        },
        promptButtonClasses: {
            type: String,
            required: false,
            default: ''
        },
        promptContainerClasses: {
            type: String,
            required: false,
            default: ''
        },
        promptTextClasses: {
            type: String,
            required: false,
            default: ''
        },
        promptTextContainerClasses: {
            type: String,
            required: false,
            default: ''
        },
    },
    data() {
        return {
            selectedIndex: Math.floor(Math.random()*this.availablePrompts.length),
            prompt: ''
        }
    },
    methods: {
        newPrompt() {
            let newIndex = Math.floor(Math.random()*this.availablePrompts.length)
            while(newIndex === this.selectedIndex) {
                newIndex = Math.floor(Math.random()*this.availablePrompts.length)
            }
            this.selectedIndex = newIndex
            this.prompt = this.availablePrompts[this.selectedIndex]
        }
    },
    created() {
        this.newPrompt()
    }
}
</script>
