<template>
    <div class="absolute hidden sm:py-0 py-2 bottom-0 sm:bottom-auto sm:top-8 sm:right-8 w-full sm:flex flex-row sm:w-52 bg-gray-800 bg-opacity-10 sm:bg-transparent text-center font-semibold tracking-wider text-lg uppercase z-10">
        <div 
            class="flex-1 cursor-pointer ease-linear duration-200"
            :class="{
                'text-gray-200 hover:text-white': lightLevel < 60,
                'text-gray-800 hover:text-black': lightLevel >= 60
            }"
            @click="aboutClicked"
        >
            About
        </div>
        <div 
            class="flex-1 cursor-pointer ease-linear duration-200"
            :class="{
                'text-gray-200 hover:text-white': lightLevel < 60,
                'text-gray-800 hover:text-black': lightLevel >= 60
            }"
            @click="galleryClicked"
        >
            Gallery
        </div>
    </div>
</template>

<script>
export default {
    props: {
        lightLevel: {
            type: Number,
            required: true
        }
    },

    methods: {
        aboutClicked() {
            this.$emit('about-clicked')
        },
        galleryClicked() {
            this.$emit('gallery-clicked')
        }
    }
}
</script>