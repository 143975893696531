<template>
    <div
        :id="id"
        class="absolute z-50 bg-opacity-0 cursor-pointer ease-in-out duration-300 element-overlay"
        :class="`${backgroundColor} hover:${backgroundOpacity}`"
        :style="{...$props}"
        @click="elementClicked"
    >
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: false
        },
        width: {
            type: String,
            required: true
        },
        height: {
            type: String,
            required: true
        },
        left: {
            type: String,
            required: true
        },
        top: {
            type: String,
            required: true
        },
        transform: {
            type: String,
            required: true
        },
        backgroundColor: {
            type: String,
            required: true
        },
        backgroundOpacity: {
            type: String,
            required: true
        },
        mixBlendMode: {
            type: String,
            required: false
        },
        borderRadius: {
            type: String,
            required: false
        }
    },
    methods: {
        elementClicked() {
            this.$emit('element-clicked')
        }
    }
}
</script>

<style>
    .element-overlay {
        border-radius: 25px;
        mix-blend-mode: color-burn;
    }
    .element-overlay:hover {
        box-shadow: 0 0 20px 2px rgba(219, 234, 254, 0.6);
    }
</style>