<template>
    <transition name="fade">
        <div id="ambianceSliders" class="absolute bottom-full mb-4 left-0 w-72 h-auto flex flex-col justify-center px-5 py-6 bg-red-600 border-2 border-gray-900 rounded-3xl text-lg tracking-wide text-white z-50">
            
            <h1 class="mb-3">Bartender At Work</h1>
            <audio ref="bartender" class="hidden" src="/bartender.mp3" loop />
            <div class="flex flex-row w-full">
                <vue-slider
                    class="flex-1 mt-1 ml-2"
                    :value="bartender"
                    @change="bartenderLevelChanged"
                    :min="0"
                    :max="100"
                    :label="false"
                    :dot-size="17"
                    :dot-style="{ borderColor: '#000', borderWidth: '2px' }"
                    :rail-style="{ backgroundColor: '#222' }"
                    :process-style="{ backgroundColor: '#222' }"
                    tooltip="none"
                />
            </div>

            <h1 class="mb-3 mt-6">Full Room</h1>
            <audio ref="room" class="hidden" src="/room.mp3" loop />
            <div class="flex flex-row w-full">
                <vue-slider
                    class="flex-1 mt-1 ml-2"
                    :value="room"
                    @change="roomLevelChanged"
                    :min="0"
                    :max="100"
                    :label="false"
                    :dot-size="17"
                    :dot-style="{ borderColor: '#000', borderWidth: '2px' }"
                    :rail-style="{ backgroundColor: '#222' }"
                    :process-style="{ backgroundColor: '#222' }"
                    tooltip="none"
                />
            </div>

            <h1 class="mb-3 mt-6">Street Noises</h1>
            <audio ref="street" class="hidden" src="/street.mp3" loop />
            <div class="flex flex-row w-full">
                <vue-slider
                    class="flex-1 mt-1 ml-2"
                    :value="street"
                    @change="streetLevelChanged"
                    :min="0"
                    :max="100"
                    :label="false"
                    :dot-size="17"
                    :dot-style="{ borderColor: '#000', borderWidth: '2px' }"
                    :rail-style="{ backgroundColor: '#222' }"
                    :process-style="{ backgroundColor: '#222' }"
                    tooltip="none"
                />
            </div>
        </div>
    </transition>
</template>

<script>
import VueSlider from 'vue-slider-component'

export default {
    components: {
        VueSlider
    },

    data() {
        return {
            bartender: 0,
            bartenderPlaying: false,
            room: 0,
            roomPlaying: false,
            street: 0,
            streetPlaying: false
        }
    },

    methods: {
        bartenderLevelChanged(value) {
            this.$refs.bartender.volume = (value / 100)
            
            // Check for play/pause logic
            if(value === 0) this.stopBartender()
            else if(this.bartender === 0 && value > 0) this.playBartender()

            this.bartender = (value / 100)
        },
        playBartender() {
            this.$refs.bartender.play()
            this.bartenderPlaying = true
        },
        stopBartender() {
            this.$refs.bartender.pause()
            this.bartenderPlaying = false
        },
        roomLevelChanged(value) {
            this.$refs.room.volume = (value / 100)
            
            // Check for play/pause logic
            if(value === 0) this.stopRoom()
            else if(this.room === 0 && value > 0) this.playRoom()

            this.room = (value / 100)
        },
        playRoom() {
            this.$refs.room.play()
            this.roomPlaying = true
        },
        stopRoom() {
            this.$refs.room.pause()
            this.roomPlaying = false
        },
        streetLevelChanged(value) {
            this.$refs.street.volume = (value / 100)
            
            // Check for play/pause logic
            if(value === 0) this.stopStreet()
            else if(this.street === 0 && value > 0) this.playStreet()

            this.street = (value / 100)
        },
        playStreet() {
            this.$refs.street.play()
            this.streetPlaying = true
        },
        stopStreet() {
            this.$refs.street.pause()
            this.streetPlaying = false
        }
    },

    mounted() {
        document.addEventListener('click', (e) => {
            // Check if click event is on the lighting div or slider, if not emit a close event
            if(
                !(e.target.id === 'ambianceSliders' ||
                e.target.classList.contains('vue-slider') ||
                e.target.classList.contains('vue-slider-process') ||
                e.target.classList.contains('vue-slider-dot-handle') ||
                e.target.classList.contains('vue-slider-dot') ||
                e.target.classList.contains('vue-slider-rail') ||
                e.target.classList.contains('ambiance-trigger'))
            ) {
                this.$emit('close')
            }
        })
    }
}
</script>