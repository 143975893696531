<template>
    <transition name="fade">
        <div class="backdrop absolute top-0 left-0 w-screen h-screen flex flex-col justify-center items-center bg-black bg-opacity-60">
            <div @click="close" class="flex text-white text-xl cursor-pointer mb-2">Close</div>
            <iframe class="flex justify-center w-screen self-center" src="https://flickrembed.com/cms_embed.php?source=flickr&layout=responsive&input=72157718946415016&sort=0&by=album&theme=default_notextpanel&scale=fit&speed=3000&limit=10&skin=default&autoplay=true" scrolling="no" frameborder="0" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>
        </div>
    </transition>
</template>

<script>
export default {
    methods: {
        close() {
            this.$emit('closed')
        }
    }
}
</script>

<style scoped>
    .backdrop {
        z-index: 99999;
    }
    iframe {
        height: auto;
        min-height: 500px;
    }
</style>