<template>
    <modal background-image="/ebc-team.jpg" @modal-closed="close">
        <div class="flex flex-col justify-center text-center relative pb-48">
            <h1 class="text-center font-bold text-3xl mb-6">About Sky Bar</h1>
            <p class="mb-3">Hidden behind the walls of Red Hat's Global Executive Briefing Center in Boston you can find the Sky Bar—a secret "speakeasy" for Red Hat's customers and partners to relax after a long day of strategic conversations and collaborating with Red Hat's technology leaders and product owners.</p>
            <p class="mb-3">We're now offering a virtual speakeasy so you can unwind or continue with conversations of interests following your Red Hat virtual executive meetings and events.</p>
            <p class="mb-3">We invite you to explore the space—you'll find some interactive activities and customizable settings to make Sky Bar the perfect virtual après-event environment.</p>
        </div>
    </modal>
</template>

<script>
import Modal from './Modal'
import ModalBehaviors from '../mixins/ModalBehaviors'

export default {
    components: {
        Modal
    },
    mixins: [ ModalBehaviors ]
}
</script>