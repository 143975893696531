<template>
    <modal @modal-closed="resetAndClose">

        <!-- Intro + Team Name -->
        <h1 class="text-center font-bold text-3xl mb-6" v-show="currentQuestion === null">Let's Play Red Hat Trivia!</h1>
        <div class="text-center mb-4 text-lg" v-show="currentQuestion === null">
            Work with your colleagues to test your Red Hat knowledge. 
            <br/><br/>
            Create a team name below and get started.
        </div>
        <div class="text-center mb-6 flex flex-col" v-show="currentQuestion === null">
            <label class="font-semibold mb-4">Team Name:</label>
            <input type="text" class="border-2 border-gray-900 text-lg p-3" v-model="teamName" />
            <div :style="{ visibility: hasProfaneTeamName ? 'visible' : 'hidden' }">
                Come on now. I take my kids to this bar!
            </div>
        </div>
        <button
            class="rounded-full border-2 border-red-600 font-bold text-xl text-red-600 py-3 bg-white hover:bg-red-600 hover:text-white ease-linear duration-150 cursor-pointer"
            @click="currentQuestion = 0"
            v-show="currentQuestion === null"
            :disabled="!teamName || hasProfaneTeamName"
        >
            Get Started
        </button>
        
        <!-- Question and Answer -->
        <div class="flex flex-col" v-show="!endGame">
            <h1 class="text-center font-bold text-3xl mb-6" v-show="currentQuestion !== null && !showAnswer">
                Question
                <br/><small>{{ currentQuestion + 1 }} / 10</small>
            </h1>
            <div class="text-center mb-10 text-lg" v-show="currentQuestion !== null && !showAnswer">
                {{ question.text }}
            </div>
            <div class="grid grid-cols-1 text-center gap-2 mb-8" v-show="currentQuestion !== null && !showAnswer">
                <div 
                    v-for="(response, index) in responses"
                    :key="response"
                    class="col border-2 border-black py-3 font-bold hover:text-white hover:bg-black hover:border-black ease-linear duration-150 cursor-pointer"
                    :class="{
                        'text-white border-red-600 bg-red-600 hover:bg-red-600 hover:border-red-600': index === selectedResponse,
                        'text-white bg-red-600 opacity-100': isCorrectAnswer(index) && showAnswer,
                        'opacity-70': !isCorrect && showAnswer
                    }"
                    @click="selectResponse(index)"
                >{{ response }}</div>
            </div>
            <button
                class="rounded-full border-2 border-red-600 font-bold text-xl text-red-600 py-3 bg-white hover:bg-red-600 hover:text-white ease-linear duration-150 cursor-pointer"
                @click="answerQuestion"
                v-show="currentQuestion !== null && !showAnswer"
                :disabled="selectedResponse === null"
            >
                Submit
            </button>

            <!-- Show Result -->
            <h1 class="text-center font-bold text-3xl mb-4" v-show="currentQuestion !== null && showAnswer && isCorrect">That's Right!</h1>
            <h1 class="text-center font-bold text-3xl mb-4" v-show="currentQuestion !== null && showAnswer && !isCorrect">Sorry, that's not it.</h1>
            <div class="text-center" v-show="currentQuestion !== null && showAnswer">
                The correct answer was<br/>
                <em class="font-bold text-xl mt-1 block">{{ correctAnswer }}</em>
            </div>
            <div class="text-center" v-show="currentQuestion !== null && showAnswer && isCorrect">
                <img src="/right.jpg" />
            </div>
            <div class="text-center" v-show="currentQuestion !== null && showAnswer && !isCorrect">
                <img src="/wrong.jpg" />
            </div>
            <div class="text-center mb-10" v-show="currentQuestion !== null && showAnswer">
                <span class="mb-2 inline-block w-full">You earned {{ isCorrect ? '10' : '0' }} points.</span>
                <span class="inline-block w-full text-xl font-medium">Your total score is {{ points }}</span>
            </div>
            <button
                class="rounded-full border-2 border-red-600 font-bold text-xl text-red-600 py-3 bg-white hover:bg-red-600 hover:text-white ease-linear duration-150 cursor-pointer"
                @click="nextQuestion"
                v-show="currentQuestion !== null && showAnswer"
            >
                Next Question
            </button>
        </div>
        
        <!-- Endgame -->
        <div class="flex flex-col" v-show="endGame">
            <h1 class="text-center font-bold text-3xl mb-4" v-show="endGame">Nice job. You got {{ points }} points!</h1>
            <div class="text-center mb-3" v-show="endGame">
                Well <strong>{{ teamName }}</strong>, that certainly is a score. For your efforts, please enjoy this randomly selected image.
            </div>
            <div class="text-center mb-6" v-show="endGame">
                Print it out, hang it on the fridge, and tell your family about your accomplishment.
            </div>
            <img class="mb-10" src="https://picsum.photos/350/250" />
            <button
                class="rounded-full border-2 border-red-600 font-bold text-xl text-red-600 py-3 bg-white hover:bg-red-600 hover:text-white ease-linear duration-150 cursor-pointer"
                @click="reset"
                v-show="currentQuestion !== null && showAnswer"
            >
                Restart
            </button>
        </div>
    </modal>
</template>

<script>
import { getRandom, shuffleObject } from '@/helpers'
import { TRIVIA } from '@/config'
const profanity = require('profanity-util')

import Modal from './Modal'
import ModalBehaviors from '../mixins/ModalBehaviors'

export default {
    components: {
        Modal
    },
    
    mixins: [ ModalBehaviors ],

    data() {
        return {
            points: 0,
            questions: getRandom(TRIVIA.questions, 10),
            currentQuestion: null,
            selectedResponse: null,
            teamName: null,
            hasProfaneTeamName: false,
            showAnswer: false,
            endGame: false
        }
    },

    computed: {
        responses() {
            if(this.currentQuestion === null) return []
            return shuffleObject(this.questions[this.currentQuestion].responses)
        },
        question() {
            if(this.currentQuestion === null) return {}
            return this.questions[this.currentQuestion]
        },
        isCorrect() {
            if(this.selectedResponse === null) return null
            return this.question.responses[this.responses[this.selectedResponse]]
        },
        correctAnswer() {
            if(this.currentQuestion === null) return null
            let responses = this.questions[this.currentQuestion].responses
            return Object.keys(responses).filter(response => responses[response])[0]
        }
    },

    watch: {
        teamName(value) {
            this.hasProfaneTeamName = !!profanity.check(value).length
        }
    },
    
    methods: {
        selectResponse(index) {
            // If you already answered don't select
            if(this.showAnswer) return
            this.selectedResponse = index
        },
        answerQuestion() {
            // If correct, add points
            if( this.isCorrect ) {
                this.points = this.points + 10
            }
            this.showAnswer = true
        },
        nextQuestion() {
            // Reset selections, increment current question, disable show answer
            this.selectedResponse = null
            this.showAnswer = false

            // Check for existence of next question, if not, end game go to leaderboard
            if(!this.questions[this.currentQuestion + 1]) {
                this.endGame = true
                return
            }
            this.currentQuestion++
        },
        isCorrectAnswer(index) {
            return this.question.responses[this.responses[index]]
        },
        resetAndClose() {
            this.close()
            // Per Evan, we won't reset this
            //setTimeout(() => Object.assign(this.$data, this.$options.data.apply(this)), 500)
        },
        reset() {
            Object.assign(this.$data, this.$options.data.apply(this))
        }
    }
}
</script>

<style>
    button[disabled] {
        opacity: 0.5;
        background: white !important;
        color: gray !important;
        border-color: gray !important;
    }
</style>