<template>
  <div id="app" class="z-0 relative flex w-screen h-screen">

    <!-- Sidebar (Mobile) -->
    <sidebar @item-tapped="sidebarItemTapped" />

    <!-- Lighting Backdrop -->
    <lighting-backdrop :light-level="lightingLevel" />

    <!-- Bar Image w/ interactive overlays -->
    <div class="relative self-start mx-auto z-0 overflow-x-scroll sm:overflow-x-hidden">
      <!-- Lighting Slider -->
      <lighting-slider
        v-show="showLightingLevelMenu" 
        :light-level="lightingLevel"
        @light-changed="lightingLevelUpdated"
        @close="closeLightingLevelMenu"
      />

      <img src="/illustration.png" class="illustration max-w-none sm:h-auto sm:w-screen sm:max-h-screen-90 md:max-w-full md:w-auto md:h-auto xl:max-h-screen-95 lg:h-auto w-auto" />

      <!-- Light Switch Overlay -->
      <element-overlay
        id="lightSwitchOverlay"
        @element-clicked="openLightingLevelMenu"
        width="4.25%"
        height="8.55%"
        left="93.3%"
        top="38.8%"
        transform="perspective(100px) rotateY(-12deg) skewY(-1deg) skewX(-1deg)"
        background-color="bg-blue-200"
        border-radius="18px"
        background-opacity="bg-opacity-40"
      />

      <!-- Discussion Overlay -->
      <element-overlay
        @element-clicked="openDiscussionCards"
        width="2.55%"
        height="5.25%"
        left="80.6%"
        top="40.5%"
        transform="perspective(100px) rotateY(-12deg) skewY(-1deg) skewX(-1deg)"
        background-color="bg-blue-100"
        mix-blend-mode="color"
        background-opacity="bg-opacity-70"
      />

      <!-- Drink Menu Overlay -->
      <element-overlay
        @element-clicked="openDrinkMenu"
        width="10.35%"
        height="9.65%"
        left="66.75%"
        top="32.23%"
        transform="perspective(100px) rotateY(-9deg) skewY(-3deg) skewX(-1deg)"
        background-color="bg-red-800"
        mix-blend-mode="color"
        background-opacity="bg-opacity-50"
        border-radius="10px"
      />

      <!-- Art Gallery Overlay -->
      <element-overlay
        @element-clicked="openArtGallery"
        width="19%"
        height="11.5%"
        left="41.5%"
        top="30.25%"
        transform="perspective(106px) rotateY(10deg) skewY(6deg) skewX(0deg)"
        background-color="bg-blue-100"
        background-opacity="bg-opacity-40"
      />

      <!-- Trivia Overlay -->
      <element-overlay
        @element-clicked="openTrivia"
        width="18%"
        height="26.25%"
        left="5.25%"
        top="16%"
        transform="perspective(192px) rotateY(61deg) skewY(14deg) skewX(0deg)"
        background-color="bg-blue-100"
        border-radius="100px"
        background-opacity="bg-opacity-30"
      />
    </div>

    <!-- Nav Items -->
    <nav-items
      :light-level="lightingLevel" 
      @about-clicked="openAbout"
      @gallery-clicked="openGallery"
    />

    <!-- About -->
    <about v-show="showAbout" @closed="closeAbout" />

    <!-- Gallery -->
    <gallery v-if="showGallery" @closed="closeGallery" />

    <!-- Trivia -->
    <trivia v-show="showTrivia" @closed="closeTrivia" />

    <!-- History/Timeline -->
    <art-gallery v-show="showArtGallery" @closed="closeArtGallery" />

    <!-- Drink Menu -->
    <drink-menu v-show="showDrinkMenu" @closed="closeDrinkMenu" />

    <!-- Discussion Cards -->
    <discussion-cards v-show="showDiscussionCards" @closed="closeDiscussionCards" />

    <!-- Ambient Noise -->
    <div class="flex-col absolute hidden sm:flex sm:left-4 lg:bottom-10 xl:bottom-10 bottom-36 w-48 h-16">
      <div class="relative w-full h-full">
        <div class="flex flex-row justify-center items-center text-white text-xl font-bold cursor-pointer w-full text-center">
          <svg @click="openAmbientNoise" class="w-1/3 mr-3 ambiance-trigger" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151.35 151.35"><g id="Layer_2" class="ambiance-trigger" data-name="Layer 2"><g id="Top_Menus" class="ambiance-trigger" data-name="Top Menus"><circle class="cls-1 ambiance-trigger" cx="75.68" cy="75.68" r="75.68"/><line class="cls-2 ambiance-trigger" x1="36.15" y1="56.7" x2="36.15" y2="94.65"/><line class="cls-2 ambiance-trigger" x1="55.91" y1="36.7" x2="55.91" y2="114.65"/><line class="cls-2 ambiance-trigger" x1="75.68" y1="56.7" x2="75.68" y2="94.65"/><line class="cls-2 ambiance-trigger" x1="95.44" y1="36.7" x2="95.44" y2="114.65"/><line class="cls-2 ambiance-trigger" x1="115.2" y1="56.7" x2="115.2" y2="94.65"/></g></g></svg>
          <span @click="openAmbientNoise" class="ambiance-trigger shadow-sm" style="text-shadow: 1px 1px 4px #000;">Ambiance</span>
        </div>
        <ambiance v-show="showAmbientNoise" @close="closeAmbientNoise" />
      </div>
    </div>

    <!-- Spotify Embed -->
    <iframe
      class="absolute right-4 xl:bottom-10 lg:bottom-12 sm:bottom-12 bottom-12 h-20 w-full sm:h-64 sm:w-80 shadow-xl" 
      src="https://open.spotify.com/embed/playlist/6M9egtOv8ZuO2YeyYSTXbU" 
      frameborder="0" 
      allowtransparency="true" 
      allow="encrypted-media"></iframe>

    <!-- Highlight Elements -->
    <div
      @mouseenter="flashOverlays(250)"
      class="flex-col absolute hidden lg:flex sm:left-1/2 lg:bottom-10 xl:bottom-10 bottom-36 h-16 bg-black bg-opacity-70 rounded-2xl justify-center items-center text-center cursor-help"
      style="width:500px;margin-left:-250px;"
    >
      <div class="flex text-white">
        Click the highlighted elements to explore interactive content.
      </div>
    </div>

    <!-- Swipe Animation -->
    <div id="swiper" class="absolute bottom-24 flex md:hidden"></div>
  </div>
</template>

<script>
import NavItems from "./components/NavItems"
import About from "./components/About"
import Gallery from "./components/Gallery"
import Trivia from "./components/Trivia"
import ArtGallery from "./components/ArtGallery"
import DrinkMenu from './components/DrinkMenu'
import DiscussionCards from './components/DiscussionCards'
import LightingBackdrop from './components/LightingBackdrop'
import LightingSlider from './components/LightingSlider'
import Ambiance from './components/Ambiance'
import ElementOverlay from './components/ElementOverlay'
import Sidebar from './components/Sidebar'

import { sleep } from './helpers'
import lottie from 'lottie-web'

export default {
  name: 'App',
  components: {
    NavItems, About, Gallery,
    Trivia, ArtGallery, DrinkMenu,
    DiscussionCards, LightingBackdrop,
    LightingSlider, ElementOverlay,
    Ambiance, Sidebar
  },
  data() {
    return {
      showAbout: false,
      showGallery: false,
      showArtGallery: false,
      showDrinkMenu: false,
      showTrivia: false,
      showDiscussionCards: false,
      showAmbientNoise: false,
      showLightingLevelMenu: false,
      lightingLevel: 65,
      overlays: []
    }
  },
  methods: {
    openAbout() { this.showAbout = true },
    closeAbout() { this.showAbout = false },
    openGallery() { this.showGallery = true },
    closeGallery() { this.showGallery = false },
    openArtGallery() { this.showArtGallery = true },
    closeArtGallery() { this.showArtGallery = false },
    openDrinkMenu() { this.showDrinkMenu = true },
    closeDrinkMenu() { this.showDrinkMenu = false },
    openTrivia() { this.showTrivia = true },
    closeTrivia() { this.showTrivia = false },
    openDiscussionCards() { this.showDiscussionCards = true },
    closeDiscussionCards() { this.showDiscussionCards = false },
    openAmbientNoise() { this.showAmbientNoise = true },
    closeAmbientNoise() { this.showAmbientNoise = false },
    openLightingLevelMenu() { this.showLightingLevelMenu = true },
    closeLightingLevelMenu() { this.showLightingLevelMenu = false },
    lightingLevelUpdated(val) { this.lightingLevel = val },
    sidebarItemTapped(item) {
      console.log(item)
      switch(item) {
        case 'drink-menu':
          this.openDrinkMenu()
          break
        case 'trivia':
          this.openTrivia()
          break
        case 'discussion-cards':
          this.openDiscussionCards()
          break
        case 'art-gallery':
          this.openArtGallery()
          break
        case 'about':
          this.openAbout()
          break
        case 'gallery':
          this.openGallery()
          break
      }
    },
    flashOverlays(initialDelay = 0) {
      // Flash hover states
      setTimeout(async () => {
        for(let i = 0; i < this.overlays.length; i++) {
          let overlay = this.overlays[i]
          if(i>0) {
            await sleep(900)
          }
          overlay.classList.add('bg-opacity-40')
          overlay.classList.add('element-overlay-hover')
          setTimeout(() => {
            overlay.classList.remove('bg-opacity-40')
            overlay.classList.remove('element-overlay-hover')
          }, 1000)
        }
      }, initialDelay)
    }
  },
  mounted() {
    lottie.loadAnimation({
      container: '#swiper',
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/data.json'
    })
    this.overlays = Array.from(document.querySelectorAll('.element-overlay')).reverse()
    this.flashOverlays(1000)
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;700&display=swap');
  html, body {
    font-family: 'Red Hat Display', sans-serif;
  }
  body {
    background: url(/bg.jpg) center center;
    background-size: 100%;
  }

  #app {
    /*background: url(/illustration.png) top center no-repeat;*/
    background-size: contain;
  }

  .element-overlay-hover {
    box-shadow: 0 0 20px 2px rgb(219 234 254 / 60%);
  }

  .cls-1{fill:#e00;}.cls-2{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:6px;}

  @media screen and (max-width: 428px) {
    .element-overlay {
      display: none;
    }
    .illustration {
      height: 80vh;
    }
  }
</style>