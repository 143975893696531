export const TRIVIA = {
    questions: [
        {
            text: "Red Hat was founded on March 26 in what year?",
            responses: {
                "1993": true,
                "1990": false,
                "1989": false,
                "1996": false
            },
        },
        {
            text: "Red Hat got its name from founder Marc Ewing who wore his grandfather’s red hat from what University?",
            responses: {
                "Cornell": true,
                "Harvard": false,
                "Texas Tech": false,
                "Boston College": false
            }
        },
        {
            text: "Red Hat Linux first appeared in 1994, with an October release called appropriately:",
            responses: {
                "Halloween": true,
                "Night of the Living Dead": false,
                "All Hallows' Eve": false,
                "Jack O' Lantern": false
            },
        },
        {
            text: "Red Hat’s initial public offering took place on August 11 of what year?",
            responses: {
                "1999": true,
                "2001": false,
                "1998": false,
                "2004": false
            },
        },
        {
            text: "Red Hat hosted its first Summit in 2005 in what city?",
            responses: {
                "New Orleans": true,
                "Philadelphia": false,
                "Boston": false,
                "San Diego": false
            },
        },
        {
            text: "The 100 millionth pull request from GitHub took place on May 31, 2017 and it was an update to what software?",
            responses: {
                "OpenShift": true,
                "OpenStack": false,
                "Red Hat Enterprise Linux": false,
                "CodeReady": false
            },
        },
        {
            text: "To mark our 25th anniversary, Governor Roy Cooper enshrined this day as Red Hat Day across North Carolina:",
            responses: {
                "March 26, 2018": true,
                "April 5, 2017": false,
                "December 10, 2018": false,
                "February 16, 2019": false
            },
        },
        {
            text: "Red Hat acquired IT automation startup Ansible on October 16 of what year?",
            responses: {
                "2015": true,
                "2014": false,
                "2016": false,
                "2017": false
            },
        },
        {
            text: "Red Hat unveiled Red Hat Enterprise Linux in what year?",
            responses: {
                "2002": true,
                "1999": false,
                "2001": false,
                "1997": false
            },
        },
        {
            text: "Red Hat’s first acquisition took place in 1999 when we acquired this design studio, to make improvements to the redhat.com website:",
            responses: {
                "Atomic Vision": true,
                "The Brand Factory": false,
                "Steelhead Horizons": false,
                "Gatsby Limited": false
            },
        },
        {
            text: "In 2002 this movie studio released Spirit, Stallion of the Cimarron, which was produced using Linux.",
            responses: {
                "DreamWorks": true,
                "Pixar": false,
                "Paramount": false,
                "Fox Studios": false
            },
        },
        {
            text: "Red Hat moved its headquarters to in 2012 to this US city:",
            responses: {
                "Raleigh, NC": true,
                "Boston, MA": false,
                "Philadelphia, PA": false,
                "Charlotte, NC": false
            },
        },
        {
            text: "Red Hat achieved $2 billion in total revenue in what fiscal year?",
            responses: {
                "2016": true,
                "2014": false,
                "2019": false,
                "2018": false
            },
        },
    ]
}

export const DRINKS = {
    'fruity': {
        name: 'Daiquiri',
        img: '/daiquiri.jpg',
        ingredients: [
            '2 ounces light rum',
            '1 ounce plus one teaspoon fresh lime juice',
            '1/2 ounce simple syrup',
            '1 lime peel twist'
        ],
        preparation: 'In a cocktail shaker filled with ice, combine rum, lime juice, and simple syrup. Shake vigorously, then strain into cocktail glass.'
    },
    'bitter': {
        name: 'Negroni',
        img: '/negroni.jpg',
        ingredients: [
            '1 ounce sweet vermouth',
            '1 ounce Campari',
            '1 ounce dry gin',
            '1 cup ice cube',
            '1 navel orange peel twist'
        ],
        preparation: 'In a chilled cocktail shaker, stir vermouth, Campari, gin, and ice until well combined. Strain into a martini glass or ice-filled rocks glass and garnish with orange twist.' 
    },
    'dry': {
        name: 'Vodka Martini',
        img: '/martini.jpg',
        ingredients: [
            'Cracked ice',
            '2 1/2 ounces Vodka',
            '1/2 ounce dry vermouth',
            '2 dashes bitters, optional',
            'Lemon twist (or 3 olives), garnish'
        ],
        preparation: 'Swirl a dash of bitters in a mixing glass or cocktail shaker, pour out. Fill with ice, combine vodka and vermouth. Stir well, about 30 seconds, then strain into martini glass. Garnish with olive or lemon twist and serve.'
    },
    'sweet': {
        name: 'Old Fashioned',
        img: '/old-fashioned.jpg',
        ingredients: [
            '1 scant teaspoon simple syrup',
            '2 dashes Angostura Bitters',
            '1 slice orange peel, including pith',
            '2 ounces good-quality rye or bourbon',
            '1 maraschino cherry'
        ],
        preparation: 'Combine simple syrup and bitters into glass. Fill glass halfway with ice, then stir. Add more ice to fill glass. Squeeze orange peel and add peel to glass, then add whiskey. Stir and garnish with cherry, swizzle stick, and straw.',
        
    },
    'choice': {
        name: 'Linux Lemonade',
        img: '/linux-lemonade.jpg',
        ingredients: [
            'Ice',
            '2 oz freshly squeezed lemon juice',
            '3 oz water',
            '2 oz blueberry simple syrup',
            '1 1/2 oz vodka (use 1 1/2 oz water for a mocktail)'
        ],
        preparation: 'In a shaker filled with ice, combine lemon juice, water, blueberry simple syrup, and vodka. Shake well. Pour cocktail into glass filled with ice. Garnish with additional blueberries and an optional lemon wedge.'
    }
}

export const PROMPTS = [
    `You have your own late night talk show, who do you invite as your first guest?`,
    `What fictional world or place would you like to visit?`,
    `You can have any fictional character as your imaginary friend, who do you choose?`,
    `What would your superpower be and why?`,
    `As a child, what did you want to be when you grew up?`,
    `You can have an unlimited supply of one thing for the rest of your life, what is it?`,
    `If you could be any animal, what would you be and why?`,
    `If you had to teach a class on one thing, what would you teach?`,
    `Assuming you're working from home these days, what's a #sharendipity you've experienced? (sharendipity = Something that happened that you would have otherwise missed if you weren't working from home.)`,
    `Where do you work most frequently from at home? (i.e. Your office? Your kitchen table? The backyard? Your bed?)`,
    `How many cups of coffee or tea do you have each morning?`,
    `What’s the last great TV show or movie you watched?`,
    `If you could learn one new personal skill, what would it be?`,
    `What’s your favorite way to get in some exercise?`,
    `Have you ever been told you look like someone famous, who was it?`,
    `What’s the most embarrassing fashion trend you used to rock?`,
    `Who is your favorite comic book hero or heroine?`,
    `Which band / artist – dead or alive would you love to see in concert?`,
    `What’s your favorite day of the week and why?`,
    `What was your first computer?`,
    `How did you start your career in IT?`,
    `How did you know you wanted to be in the IT field?`,
    `What is your biggest hiring challenge right now?`,
    `What people skill do you value most in teammates?`,
    `What is the most important skill for an aspiring CIO to develop?`,
    `What’s the best piece of career advice you share with others?`,
    `What’s the best career advice that you’ve received in the IT industry?`,
    `What technology buzzword drives you crazy?`,
    `What's something you do on your smartphone to kill time?`,
    `What was the worst job you ever had? Why?`,
    `What is your tech pet peeve?`,
    `Name something you think will be obsolete in 10 years?`,
    `What do you think is most misunderstood about the role you're in?`,
    `What's your top way to de-stress while working from home?`,
    `What’s the craziest time of night you’ve been called about an outage?`,
    `What's the worst technical advice you've ever received in your IT career?`,
    `What's the hardest IT decision you've ever had to make?`,
    `Have you ever experienced an outage that impacted your holiday, family plans or date night? What was it?`,
    `Do you think there is such a thing as “too much automation”?`,
    `If you could go back to the beginning of your career, and tell yourself to learn some technical skill, what would that be?`,
    `If you could go back to the beginning of your career, and tell yourself to not waste time with some technical skill, what would that be?`,
    `What’s your most useful tech shortcut or trick that you use on a daily basis?`,
    `What technology are you ashamed to admit that you had to work with?`,
    `What’s that thing you wish you never saw when you were fixing someone’s computer/phone?`,
    `What’s your favorite tech problem to help customers with?`,
    `What is one thing you wish you could change about the tech industry today?`,
    `Why do you do what you do?`,
    `Would you rather give up your smartphone or your laptop/computer?`,
    `What is one thing you hope to accomplish in your tech career?`
]