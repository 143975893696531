<template>
    <div
        id="lighting-backdrop" 
        class="absolute w-screen h-screen top-0 left-0 bg-black z-0"
        :style="{ opacity: (100 - lightLevel) / 100 }"
    ></div>
</template>

<script>
export default {
    props: {
        lightLevel: {
            type: Number,
            required: true
        }
    }
}
</script>

<style>
    #lighting-overlay {
        background-color: #2d2d2d;
        mix-blend-mode: soft-light;
    }
</style>